import { useEffect, useState, useRef } from "react";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Button from "../../components/Button";
import Tooltip from "../../components/Tooltip/Tooltip";
import styles from "./Investing.module.sass";
import { TabletDesktop } from "../../components/Responsive";
import { bugReport } from "../../config/requests/requests";
import { getNetwork, switchNetwork } from "@wagmi/core";
import { useDisconnect, useAccount, useNetwork } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useWalletConnectorContext } from "../../context/contract";
import { formatEther, parseEther } from "viem";
import { VideoPopup } from "../../components/Popups/VideoPopup";
import { parseInterestRate } from "../../tools/common";
import { bnbRateRequest, userExistsRequest, getContract } from "../../config/requests/requests";
import { useGlobalStateContext } from "../../context/globalState";
import { bsc } from "viem/chains";

import wrongNetworkImage from "../../assets/img/wrong-network.png";
import * as videoTutorial from "../../assets/video"
import AddPartnerPlug from "../../components/AddPartnerPlug";

const Investing = () => {
  const { t, i18n } = useTranslation();
  const cookies = new Cookies();

  const [wrongNetworkAlert, setWrongNetworkAlert] = useState(false);
  const [bnbRate, setBnbRate] = useState(0);
  const [baseUrl, setBaseUrl] = useState(null);
  const [currencyBnb, setCurrencyBnb] = useState(true);
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [isVideoOpened, setIsVideoOpened] = useState(false);
  const [addPartnerPlugOpened, setAddPartnerPlugOpened] = useState(false)

  const [investmentInputValue, setInvestmentInputValue] = useState(0);
  const [isUserExist, setIsUserExist] = useState(true);
  const [refCode, setRefCode] = useState(null);
  const [isPartnerDeposited, setIsPartnerDeposited] = useState(undefined);

  const investmentLabelRef = useRef(null);
  const hiddenInvestmentValue = useRef(null);

  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { isConnected } = useAccount();
  const { chain } = useNetwork();
  const {
    address,
    partnerAddress,
    balance,
    makeDeposit,
    minDepositValue,
    minWithdrawValue,
    investmentAmount,
    interestRate,
    isDeposited
  } = useWalletConnectorContext();

  const { currency, setCurrency } = useGlobalStateContext();

  useEffect(() => {
    setIsWalletConnected(isConnected);
  }, [isConnected]);

  const connectHandler = () => {
    isConnected ? disconnect() : open();
  };

  const handleToggleCurrency = () => {
    setCurrencyBnb(!currencyBnb);
    setCurrency(!currencyBnb ? "bnb" : "usd");
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    const newValue = value.replace(/[^0-9.]/g, "");
    setInvestmentInputValue(newValue);
  };

  useEffect(() => {
    // Update label position on input change
    if (hiddenInvestmentValue.current && investmentLabelRef.current) {
      investmentLabelRef.current.style.left = `calc(50% + ${
        hiddenInvestmentValue.current.getBoundingClientRect().width / 2 -
        investmentLabelRef.current.getBoundingClientRect().width / 2
      }px)`;
    }
  }, [
    isConnected,
    investmentInputValue,
    hiddenInvestmentValue.current,
    investmentLabelRef.current,
  ]);

  useEffect(() => {
    const userPartnerAddressls = localStorage.getItem("partner-address");
    const userPartnerAddresscs = cookies.get("partner-address");
    
    if(isDeposited && partnerAddress) {
      setRefCode(partnerAddress);
    } else if(userPartnerAddressls || userPartnerAddresscs){
      setRefCode(userPartnerAddressls || userPartnerAddresscs);
    }

    // when partner is exist we check is he made deposit
    console.log('partnerAddress', partnerAddress, isDeposited)
    if(isDeposited && partnerAddress) {
      getContract(partnerAddress, "stake").then(r => {
        if (r) {
          const [, , , , , deposited] = r;
          setIsPartnerDeposited(deposited);
        }
      });
    }
  }, [partnerAddress])

  useEffect(() => {
    // set current domain
    setBaseUrl(`${window.location.protocol}//${window.location.hostname}`);

    // get currency bnb rate
    try {
      bnbRateRequest()
        .then((res) => {
          res[1] && setBnbRate(res[1]);
        })
        .catch((err) => {
          console.log("Request: BNB rate received an error:", err);
        });
    } catch (err) {
      console.log(
        "Try to make request for get BNB rate received an error:",
        err
      );
    }
  }, []);

  useEffect(() => {
    if(chain?.id) setWrongNetworkAlert(chain.id !== bsc.id);
  }, [chain?.id]);

  const investingHandler = () => {
    if (
      address &&
      balance &&
      investmentInputValue >= formatEther(minDepositValue) &&
      parseFloat((+formatEther(balance)).toFixed(5)) >= investmentInputValue
    ) {
      if (refCode !== undefined && refCode !== null && refCode !== "true") {
        const correctedRef = refCode.substr(0, 42);
        makeDeposit(parseEther(investmentInputValue.toString()), correctedRef);
      } else {
        makeDeposit(parseEther(investmentInputValue.toString()));
      }
    }
  };

  const switchNetworkHandler = async () => {
    const network = getNetwork()

    try {
      // switchToBSC() // self-maden
      if (network.chain?.id !== 56) {
        await switchNetwork({ chainId: 56 })
      }
    } catch (err) {
      console.log("Switch network error", err);
      bugReport(`Investing section: Switch network error by link: ${err}`).then(
        () => console.log("bug successfully sended")
      );
    }
  };

  /* Show AddPartnerPlug if user haven't refCode */
  useEffect(() => {
    const timerId = setTimeout(() => {
      if(!address) return
      userExistsRequest(address).then(() => {
        setIsUserExist(true)
      }).catch(err => {
        console.log('userExistsRequest: user isn\'t exist.', err)
        setIsUserExist(false)
      })
      if(!isUserExist) {
        setAddPartnerPlugOpened(true)
      } else {
        setAddPartnerPlugOpened(false)
      }
    }, 1_000)

    return () => {
      clearTimeout(timerId);
    };
  }, [address, refCode, isUserExist])

  return (
    <>
    {/* isPartnerDeposited === false condition
        the condition describes a case that arose after the migration of old users from the previous
        smart contract, namely: if a deposit has already been made from the user’s active wallet,
        then the referral address of his partner will come from the smart contract, and if on this ref address
        no deposits has been made (isDeposited = false) - then we replace this address in the Inviter parameter
        of the registration form with another one (in case unser: 0x3F806da428AB866377FDBb0553A692d008A2529F)
    */}
      { addPartnerPlugOpened && <AddPartnerPlug refCode={isDeposited && isPartnerDeposited === false ? '0x3F806da428AB866377FDBb0553A692d008A2529F' : refCode} close={() => setAddPartnerPlugOpened(false)} /> }
      <section className={styles.investing} id="section-investing">
        <div className="container">
          <div className={styles.inner}>
            {/* {isLeave && (
              <div className={styles.alertBox}>
                <div className={styles.image}>
                  <img src={exitIconkImage} />
                </div>
                <div className={styles.text}>
                  <p>{t("errorMessages.isLeave.0")}</p>
                  <p>{t("errorMessages.isLeave.1")}</p>
                </div>
              </div>
            )} */}
            {wrongNetworkAlert && (
              <div className={styles.alertBox}>
                <div
                  className={styles.closeBtn}
                  onClick={() => setWrongNetworkAlert(false)}
                >
                  <svg
                    width="10"
                    height="11"
                    viewBox="0 0 10 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1.5L5 5.5M9 9.5L5 5.5M5 5.5L9 1.5L1 9.5"
                      stroke="#252526"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className={styles.image}>
                  <img src={wrongNetworkImage} />
                </div>
                <div className={styles.text}>
                  <p>{t("investing.wrongNotify.text.0")}</p>
                  <p>
                    {t("investing.wrongNotify.text.1")}{" "}
                    <span
                      className={styles.changeNetwork}
                      onClick={switchNetworkHandler}
                    >
                      {t("investing.wrongNotify.link")}
                    </span>
                  </p>
                </div>
              </div>
            )}
            <div
              className={classNames(
                styles.main,
                !isWalletConnected && styles.mainSimple
              )}
            >
              {/* <div className={styles.label}>{t("investing.label")}</div> */}
              {isWalletConnected ? (
                <>
                  <TabletDesktop>
                    <h2 className={styles.title}>{t("investing.title")}</h2>
                  </TabletDesktop>
                  <div className={styles.panel}>
                    {balance ? (
                      <div className={styles.balance}>
                        <div className={styles.balanceLabel}>
                          {t("investing.walletBalance")}
                        </div>
                        <div className={styles.balanceValue}>
                          {currencyBnb && balance
                            ? parseFloat((+formatEther(balance)).toFixed(5))
                            : parseFloat(
                                (+formatEther(balance) * bnbRate).toFixed(5)
                              )}{" "}
                          {currency}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <a
                      className={styles.download}
                      href={`${baseUrl}/docs/onepage-${i18n.language}.pdf`}
                      target="_blank"
                      download
                    >
                      <TabletDesktop>
                        <div className={styles.downloadLabel}>
                          {t("investing.downloadLabel")}
                        </div>
                      </TabletDesktop>
                      <div className={styles.downloadIcon}>
                        <svg
                          className={styles.downloadIconI}
                          width="25"
                          height="20"
                          viewBox="0 0 25 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 9V18.5H23.5V9"
                            stroke="#047B37"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.5 13.5V1M12.5 1L16 4.5M12.5 1L9 4.5"
                            stroke="#047B37"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </a>
                  </div>
                  {investmentAmount ? (
                    <div className={styles.dailyRewards}>
                      <div className={styles.dailyRewardsLabel}>
                        {t("investing.dailyRewards")}
                      </div>
                      <div className={styles.dailyRewardsValue}>
                        {currencyBnb
                          ? parseFloat(
                              +(
                                formatEther(investmentAmount) *
                                parseInterestRate.coefficient(interestRate)
                              ).toFixed(5)
                            )
                          : parseFloat(
                              (
                                +(
                                  formatEther(investmentAmount) *
                                  parseInterestRate.coefficient(interestRate)
                                ) * bnbRate
                              ).toFixed(5)
                            )}{" "}
                        {currency}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className={styles.sum}>
                    <div
                      className={styles.inputField}
                      id="onboaring__investingField"
                    >
                      {investmentInputValue < 0.3 && (
                        <div className={styles.customHint}>
                          <div className={styles.inner}>
                            {t("investing.hint.label", { amount: 0.3 })}
                          </div>
                        </div>
                      )}
                      <input
                        // defaultValue={0}
                        value={investmentInputValue}
                        type="text"
                        maxLength={10}
                        className={styles.investmentInput}
                        if="investment-input"
                        onChange={handleInputChange}
                        style={
                          investmentLabelRef.current && {
                            paddingRight: `${
                              investmentLabelRef.current.getBoundingClientRect()
                                .width
                            }px`,
                          }
                        }
                      />
                      <label
                        htmlFor="investment-input"
                        className={styles.label}
                        ref={investmentLabelRef}
                      >
                        BNB
                      </label>
                      <div
                        className={styles.valueWidthCount}
                        ref={hiddenInvestmentValue}
                      >
                        {investmentInputValue}
                      </div>
                    </div>
                  </div>
                  <button
                    className={styles.currency}
                    onClick={handleToggleCurrency}
                  >
                    <span
                      className={classNames(
                        styles.currencyBg,
                        currencyBnb && styles.currencyBgActive
                      )}
                    />
                    <span
                      className={classNames(
                        styles.currencyLabel,
                        currencyBnb && styles.currencyLabelActive
                      )}
                    >
                      BNB
                    </span>
                    <span
                      className={classNames(
                        styles.currencyLabel,
                        !currencyBnb && styles.currencyLabelActive
                      )}
                    >
                      USD
                    </span>
                  </button>
                </>
              ) : (
                <>
                  <h2 className={styles.title}>{t("investing.title")}</h2>
                  <div className={styles.subtitle}>
                    {t("investing.connectWalletTitle")}
                  </div>
                  <div className={styles.footer}>
                    <Button
                      text={t("investing.connectWalletButtonLabel")}
                      className={styles.footerButton}
                      onClick={connectHandler}
                    />
                    <div
                      className={styles.howToInvestLink}
                      onClick={() => setIsVideoOpened(!isVideoOpened)}
                    >
                      {t("investing.howToInvest")}
                    </div>
                  </div>
                </>
              )}
            </div>
            {isWalletConnected && (
              <div className={styles.body}>
                <div className={styles.limit}>
                  <div className={styles.limitLabel}>
                    {t("investing.maximumInvestment")}
                  </div>
                  <div
                    className={styles.limitSum}
                    onClick={() => {
                      balance && setInvestmentInputValue(
                        parseFloat((+formatEther(balance)).toFixed(5))
                      );
                    }}
                  >
                    <span className={styles.limitSumValue}>
                      {balance && parseFloat((+formatEther(balance)).toFixed(5))}
                    </span>
                    <span className={styles.limitSumCurrency}>BNB</span>
                  </div>
                </div>
                {interestRate && (
                  <div className={styles.percentage}>
                    <div className={styles.percentageLabel}>
                      {t("investing.percentageRewards")}
                      <Tooltip
                        classIcon={styles.percentageTooltipIcon}
                        classBox={styles.percentageTooltipBox}
                      >
                        {t("investing.withdrawRewardBtnTooltip", {
                          minimumInvestment: `${formatEther(
                            minWithdrawValue
                          )} BNB`,
                        })}
                      </Tooltip>
                    </div>
                    <div className={styles.percentageValue}>
                      {parseInterestRate.percent(interestRate)}% ~{" "}
                      {currencyBnb
                        ? parseFloat(
                            +(
                              formatEther(investmentAmount) *
                              parseInterestRate.coefficient(interestRate)
                            ).toFixed(5)
                          )
                        : parseFloat(
                            (
                              +(
                                formatEther(investmentAmount) *
                                parseInterestRate.coefficient(interestRate)
                              ) * bnbRate
                            ).toFixed(5)
                          )}{" "}
                      <span className={styles.percentage__currency}>
                        {currency}
                      </span>{" "}
                      {t("investing.daily")}
                    </div>
                  </div>
                )}
                <div className={styles.footer}>
                  <Button
                    text={t("investing.buttonLabel")}
                    id="onboaring__startInvesting"
                    className={styles.button}
                    size="Md"
                    disabled={
                      !address ||
                      investmentInputValue < formatEther(minDepositValue) ||
                      parseFloat((+formatEther(balance)).toFixed(5)) <
                        investmentInputValue
                    }
                    onClick={investingHandler}
                  />
                  <div
                    className={styles.howToInvestLink}
                    onClick={() => setIsVideoOpened(!isVideoOpened)}
                  >
                    {t("investing.howToInvest")}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      {isVideoOpened && i18n.language && (
        <VideoPopup
          videoSource={videoTutorial[i18n.language]}
          setIsVideoModalOpen={setIsVideoOpened}
        />
      )}
    </>
  );
};

export default Investing;
