import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import s from "./AddPartnerPlug.module.sass";
import cn from "classnames";
import { toast } from "react-hot-toast";
import Button from "../Button";
import { sha256 } from "js-sha256";
import { useSignMessage } from "wagmi";
import { useWalletConnectorContext } from "../../context/contract";
import { getContract, getServerTimestamp, userRegistrationRequest, bugReport } from "../../config/requests/requests";
import { formValidator } from "../../tools/common";
import { mainDomain } from "../../config/constants";

const AddPartnerPlug = ({
  close,
  refCode,
}) => {
  const { t } = useTranslation();
  const { address, isDeposited } = useWalletConnectorContext();

  const [requestedTimestamp, setRequestedTimestamp] = useState(null)

  const inputRef = useRef(null);

  const { signMessage, data, isLoading, isError, error } = useSignMessage({
    onSuccess(data) {
      const {value: inviterValue} = inputRef.current;
      console.log('Successfully signed', data)
      // registration request
      userRegistrationRequest(address, inviterValue.toLowerCase(), requestedTimestamp, data).then(() => {
        close();
        localStorage.setItem('partner-address', inviterValue);
      }).catch(err => {
        console.error('userRegistrationRequest error: ', err)
        toast.error("Something went wrong during your registration. Please contact technical support!");
        bugReport(
          `Registration (userRegistrationRequest) error: ${err}; \n Response data: ${err?.response?.data}\n Address: ${address}; \n Inviter: ${inviterValue.toLowerCase()}\n Timestamp: ${requestedTimestamp}\n Signature: ${data}`
        ).then(() => console.log("bug successfully sended"));
      })
    },
    onError(error) {
      console.error("Error signing message:", error);
    },
  });

  const signRequest = () => {
    const {value: inviterValue} = inputRef.current;
    if(!inviterValue) return
    getServerTimestamp().then(r => {
      const {data: timestamp} = r;
      setRequestedTimestamp(timestamp)
      const nonce = `${address.toLowerCase()}${inviterValue.toLowerCase()}${timestamp}`
      const nonceHash = sha256(nonce)
      signMessage({ message: `Sign the message to confirm your registration! Nonce:${nonceHash}` });
    })
  }

  const addPartnerHandler = () => {
    const {value} = inputRef.current;

    if(value) {
      if(address === value) {
        toast.error("Your wallet format is incorrect. Please double-check that you are entering the correct address.");
        return console.log('your address can\'t be the same as that your referral');
      }

      if(!formValidator.checkReferral(value).status) {
        toast.error("Your wallet format is incorrect. Please double-check that you are entering the correct address.");
        return console.log('invalid referral code.');
      }

      const readStake = getContract(value, "stake");

      readStake.then((resp) => {
        if (resp) {
          const [, , , , , deposited] = resp;
          if(deposited) signRequest()
          if(!deposited) toast.error("The address you want to use as a referral does not have deposits. You must indicate the referral who made the deposit.");
        }
      }).catch((err) => {
        toast.error("Something went wrong. Stake read error.");
        console.log("getContract: stake", err);
      });
    }
  }

  return (
    <div className={s.addParnerPlugWrapper}>
      <div className={s.addParnerPlug}>
        <div className={s.addParnerPlug__text}>
          {
            !isDeposited ? (<span>You haven't partner yet. Please add your partner.</span>) : (<span>You are not registered in our system, please submit our form</span>)
          }
        </div>
        <div className={s.addParnerPlug__inputWrapper}>
          <input type="text" name="parner-address" defaultValue={refCode} className={s.addParnerPlug__input} ref={inputRef} disabled={isDeposited} />
        </div>
        {isDeposited && <div className={s.addParnerPlug__notify}>Changing the inviter's address is not available. A deposit has already been made from the wallet address.</div>}
        <div className={s.addParnerPlug__control}>
          <Button text="Return to site" className={cn(s.button, s.addParnerPlug__returnButton)} link={`https://${mainDomain}`} />
          <Button text="Confirm" className={cn(s.button, s.addParnerPlug__addParner)} onClick={addPartnerHandler} />
        </div>
      </div>
    </div>
  );
};

export default AddPartnerPlug;
